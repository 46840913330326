// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploaded-image[data-v-41a469b5] {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-widget[data-v-41a469b5] {
  width: 100%;
  height: 100%;
  background-size: 66px 55px;
  background-color: #f4f4f4;
  background-repeat: no-repeat;
  background-position: center;
}
.image-widget.error[data-v-41a469b5] {
  background-size: 76px;
}
.flipped[data-v-41a469b5] {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
