<template>
    <div class="tv-wrapper" :key="refreshKey" :style="[element.styles]">
        <div class="tv-widget" v-if="!this.element?.settings?.channels.length">
            <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1.899" y="7.345" width="48.373" height="31.097" rx="4.031" fill="#fff" stroke="#484848"
                    stroke-width="3.455" />
                <path d="M18.888 45.928h14.396" stroke="#484848" stroke-width="3.455" stroke-linecap="round" />
                <circle cx="42.21" cy="15.407" r="2.879" fill="#484848" />
            </svg>
        </div>
        <div class="tv-live" v-else>
            <EmptyView v-if="!this.stb">
                <template v-slot:icon>
                    <svg width="104" height="104" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1.899" y="7.345" width="48.373" height="31.097" rx="4.031" fill="#4195FF" stroke="#DBDBDB" stroke-width="3.455"/>
                        <path d="M18.888 45.928h14.396" stroke="#DBDBDB" stroke-width="3.455" stroke-linecap="round"/>
                        <circle cx="42.21" cy="15.407" r="2.879" fill="#fff"/>
                    </svg>
                </template>
                <template v-slot:title><span>{{$t('widgets.playingTV')}}</span></template>
                <template v-slot:text><span>{{ this.$t('widgets.tvLive') }}</span></template>
            </EmptyView>
            <div class="tv-screen" id="stb-video" v-else></div>
            <Transition>
                <div class="tv-rect" :style="computedStyle" v-if="showOSD">
                    <div class="tv-navigation">
                        <div class="channel-info" :class="{'opacity-0': (channelListLength === 2 && channelIndex === 0)}">
                            <span>
                                <svg fill="#fff" height="30px" width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                    viewBox="0 0 330 330" xml:space="preserve">
                                <path id="XMLID_224_" d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394
                                    l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393
                                    C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"/>
                                </svg>
                            </span>
                            <div>{{ channelList[channelIndex-1] ? channelList[channelIndex-1].name : channelList[channelListLength-1] ? channelList[channelListLength-1].name : '&nbsp;' }}</div>
                        </div>
                        <div class="channel-info active">
                            <span class="channel-nr">{{ String(channelIndex+1).padStart(2, '0')  }}</span>
                            <div>{{ channelList[channelIndex]?.name }}</div>                    
                        </div>
                        <div class="channel-info" :class="{'opacity-0': (channelListLength === 2 && channelIndex === 1)}">
                            <span>
                                <svg fill="#fff" height="30px" width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                    viewBox="0 0 330 330" xml:space="preserve">
                                <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                                    c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                                    s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"/>
                                </svg>
                            </span>
                            <div>{{ channelList[channelIndex+1] ? channelList[channelIndex+1].name : channelList[0] ? channelList[0].name : '&nbsp;' }}</div>
                        </div>
                    </div>
                </div>
                <div class="tv-rect" :style="computedStyle" v-else-if="showNumberOSD">
                    <div class="tv-navigation">
                        <div class="channel-info"></div>
                        <div class="channel-info active">
                            <span class="channel-nr">{{ number }}</span>
                        </div>
                        <div class="channel-info"></div>
                    </div>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
import EmptyView from '@/components/common/EmptyView.vue';
import { onKeyStroke } from '@vueuse/core'
import { debounce } from 'lodash';
import { useOnline } from '@vueuse/core'

export default {
    components: { EmptyView },
    name: 'TVWidget',
    props: ["element", "preview", "stb", "ready"],
    setup() {
        const online = useOnline()
        return { online }
    },
    data() {
        return {
            channelIndex: 0,
            channelList: this.element.settings?.channels || [],
            channelListLength: this.element.settings?.channels.length | 0,
            refreshKey: false,
            number: '',
            showOSD: false,
            showNumberOSD: false,
            timeout: false,
            timeoutId: null
        }
    },
    mounted() {
        this.refreshKey = !this.refreshKey;
        if(this.stb) 
            this.addKeyListeners();
    },
    beforeUnmount() {
        if (!this.stb) return;
        this.stopTvChannel();
        this.hideTvChannel();
    },
    computed: {
        computedStyle(){
            return {
                top: `${(this.element.height / 2) - 75}px`,
            }
        }
    },
    methods: {
        stopTvChannel(){
            try {
                // eslint-disable-next-line no-undef
                NBrowser_CachedPlayer.stop();
            } catch (e) {
                console.log(
                    '%cNBrowser_Player - Stop',
                    'color: red',
                );
            }
        },
        hideTvChannel(){      
            try {
                // eslint-disable-next-line no-undef
                NBrowser_CachedPlayer.hidePlayer();
            } catch (e) {
                console.log(
                '%cNBrowser_Player - Hide',
                'color: red',
                );
            }
        },
        async setTvChannel(channel = this.channelIndex){
            try {
                this.showOSD = false;
                this.showNumberOSD = false;
                this.number = '';

                // eslint-disable-next-line no-undef
                NBrowser_CachedPlayer.playInWindow(this.element.settings.channels[channel].value, this.element.left, this.element.top, this.element.width, this.element.height, true);
            } catch (e) {
                console.log(
                '%cNBrowser_Player - Play',
                'color: lime',
                );
            } 
        },
        addKeyListeners() {           
            this.debouncedSetTvChannel = debounce( async() => {
                await this.setTvChannel();
            }, 1500);

            this.debouncedSetChannelIndex = debounce( async(nr) => {
                if(nr < 1 || nr > this.channelListLength) {
                    this.showOSD = true;
                    this.number = '';
                    this.debouncedSetTvChannel();
                    
                    return;
                }
    
                if(this.channelIndex === nr-1) {
                    this.showOSD = true;
                    this.debouncedSetTvChannel();
                }

                this.channelIndex = nr-1;
            }, 1000);

            onKeyStroke(['ArrowDown', 'ArrowUp', 'PageDown', 'PageUp' ], (e) => {               
                this.changeChannel(e.key);
            })

            onKeyStroke(['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'], (e) => {
                if(this.showOSD) return;
                if(this.number.length > 2) { 
                    return;
                }
                this.number += e.key;
            }, { dedupe: true })

            // LG Platform Key Codes 
            if(this.$route.query.platform 
                && this.$route.query.platform.toLowerCase().includes('lg'))
                onKeyStroke((e) => {
                    console.log(e.keyCode);
                    // 1100, Description: Brightness up key
                    if(e.keyCode === 1100)
                        this.changeChannel('ArrowUp');
                    
                    // 1101, Description: Brightness down key
                    if(e.keyCode === 1101)
                        this.changeChannel('ArrowDown');                
                })
                
        },
        changeChannel(key) {  
            if(this.timeout) return;        
            if(this.channelListLength === 1) return;
            switch(key) {
                case 'ArrowDown':
                    this.channelIndex = (this.channelIndex + 1) % this.channelListLength;
                    break;
                case 'ArrowUp':
                    this.channelIndex = (this.channelIndex - 1 + this.channelListLength) % this.channelListLength;
                    break;
                case 'PageDown':
                    this.channelIndex = (this.channelIndex - 1 + this.channelListLength) % this.channelListLength;
                    break;
                case 'PageUp':
                    this.channelIndex = (this.channelIndex + 1) % this.channelListLength;
                    break;
                default:
                    break;
            }
            
        }
    },
    watch: {
        "element.settings.url"() {
            this.refreshKey = !this.refreshKey;
        },
        online(status) {
            console.log('Connection Status: ' + status)
            if(status) this.setTvChannel();
        },
        number(val){
            if(!val || this.showOSD) return;

            if(!this.showNumberOSD) {
                this.showNumberOSD = true;
                this.stopTvChannel();
                this.hideTvChannel();
            }

            const nr = parseInt(val);
            this.debouncedSetChannelIndex(nr);
        },
        channelIndex() {
            this.timeout = true;
            if(this.timeoutId) clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.timeout = false;
            }, 500);

            if(!this.showOSD){
                this.showOSD = true;
                this.stopTvChannel();
                this.hideTvChannel();
            }
            
            this.debouncedSetTvChannel();
            
        },
        ready: {
            immediate: true,
            handler(val) {
                if (val) {
                    if (!this.stb) return;
                    this.setTvChannel();
                }
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.tv-wrapper {
    width: 100%;
    height: 100%;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tv-widget {
    display: flex;
}

:deep(.tv-live) {
    .icon{
        background: unset;
        width: 104px;
        height: 104px;
        margin-bottom: 15px;
    }
    .text-1{
        font-size: 24px;
    }
    .text-2{
        font-size: 20px;
    }
}

.tv-screen {
    background: black;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.tv-rect {
    position: absolute;
    top: 25%;
    right: 0;
    width: 311px;
    height: auto;
    background-color: rgba(115, 115, 115, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 15px 0 0 15px;
    box-shadow: 0 4.3px 5.7px 0 rgba(0, 0, 0, 0.18);
    z-index: 10000;

    .tv-navigation {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
        height: 100%;
        color: #fff;

        .channel-info{
            display: flex;
            flex-direction: row;
            align-items: center;
            max-height: 54px;
            min-height: 54px;
            gap: 15px;
            padding-left: 15px;

            &.active{
                background-color: rgba(0, 0, 0, 0.4);
            }

            span {
                width: 50px;
                text-align: left;
                display: flex;
                justify-content: center;

                &.channel-nr {
                    font-size: 42.7px;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.5px;
                }
            }
            
            div {
                font-size: 24.2px;
                font-weight: 500;
                line-height: normal;
                text-align: left;
                color: #fff;
            }
        }
        
        
        
    }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>