// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container1[data-v-308ad656] {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-weight: 600;
}
.airport-icon[data-v-308ad656] {
  display: flex;
  height: 33.7px;
  width: 40px;
}
.airport-icon svg[data-v-308ad656] {
  width: 100%;
  height: 100%;
}
svg.special[data-v-308ad656] {
  scale: 0.8;
}
.titles1[data-v-308ad656] {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 61px;
  width: 100%;
  height: 115.4px;
  line-height: 0.9;
  font-weight: normal;
}
.titles1 span[data-v-308ad656] {
  font-size: 32px;
}
.subtitles1[data-v-308ad656] {
  flex-direction: row;
  display: flex;
  width: 100%;
  min-height: 88.6px;
  height: 88.6px;
  align-items: center;
  justify-content: space-around;
  color: white;
  font-size: 25px;
}
.body1[data-v-308ad656] {
  height: calc(100% - 138px);
  width: 100%;
  align-items: center;
}
.row1[data-v-308ad656] {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 88px;
}
.item[data-v-308ad656] {
  display: flex;
  width: 20%;
  height: 88px;
  line-height: 1.25;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.item .smaller[data-v-308ad656] {
  font-size: 24px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
