// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploaded-video[data-v-51332656] {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}
.video-overlay[data-v-51332656] {
  text-shadow: 0 0 3px #000;
  color: white;
}
.stb-video[data-v-51332656] {
  width: 100%;
  height: 100%;
  background: black;
}
video[data-v-51332656] {
  height: inherit;
  width: inherit;
  object-fit: contain;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
