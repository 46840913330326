<template>
  <div class="empty-view unselectable">
    <div class="icon"><slot name="icon"/></div>
    <div class="text-1"><slot name="title"/></div>
    <div class="text-2"><slot name="text"/></div>
  </div>
</template>

<script>
export default {
  name: 'EmptyView',
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.empty-view {
  place-items: center;
  margin: auto;
  display: flex;
  gap: 4px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: 100%;

  .text-1 {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.5px;
    color: #8d8d8d;
  }
  .text-2 {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0.5px;
    color: #8d8d8d;
    white-space: pre-line;
  }

  & .icon {
    background: #ededed;
    border-radius: 5px;
    width: 57px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
  }
}
</style>
