<template>
  <div class="uploaded-video">
    <template v-if="!stb">
      <video class="video-tag" v-if="preview" autoplay loop muted>
        <source :src="this.file" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <div
        v-else
        id="VideoWidget"
        :style="{
          'background-image': `${
            this.file && !this.error ? `` : this.error ?  `url('../video_error.svg')` :  `url('../video.svg')`
          }`,
          'background-size': 
            `${this.file && !this.error ? 'cover' : 
                this.error ? '76px' : '66px'}`,
          'background-color': `${this.file && !this.error ? '#000' : '#f4f4f4'}`,
          'background-repeat': 'no-repeat',
          'background-position': 'center',
        }"
        class="uploaded-video"
        :class="{error: this.error}"
      >
        <video class="video-tag" webkit-playsinline playsinline @loadedmetadata="logDuration" :key="this.file" v-if="this.file" muted ref="videoPlayer">
          <source :src="`${this.file}`" type="video/mp4" @error="handleSourceError">
          Your browser does not support the video tag.
        </video>
      
        <div v-if="this.file && !this.error" class="video-overlay position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
        :style="{
          'background-image': `${`url('../video_play.svg')`}`,
          'background-size': '66px',
          'background-repeat': 'no-repeat',
          'background-position': 'center',
        }"> 
          
        </div>
      </div>
    </template>
    <template v-else>
      <div class="stb-video" id="stb-video"></div>
    </template>
  </div>
</template>

<script>
import { composerStore } from "@/store";

export default {
  name: "video-widget",
  props:["element", "index", "preview", "stb", "ready"],
  setup() {
    const elementsStore = composerStore();
    return { elementsStore };
  },
  data() {
    return {
      error: false,
      reader: new FileReader(),
      readerFile: true,
      file: this.element.file,
      thumbnail: this.element.file === null ? this.element.src : 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=',
    };
  },
  watch:{
    "element.file"(val){
      this.error = false;
      this.file = val;
    },
    file(val){
      this.elementsStore.slideElements[this.index].file = val;
    },
    ready: {
      immediate: true,
      handler(val) {
        if(val){
          if(!this.stb) return;
          try {
            // eslint-disable-next-line no-undef
            NBrowser_CachedPlayer.playInWindow(this.file, this.element.left, this.element.top, this.element.width, this.element.height, true);
          } catch(e){
            console.log(
                '%cNBrowser_CachedPlayer - Play',
                'color: lime',
            );
          }
        }
      }
    }
  },
  beforeUnmount(){
    this.stopNBrowser();
  },
  methods: {
    handleSourceError(event) {
      this.error = true;
      console.error('An error occurred with the video source:', event);
      // Handle specific source error
    },
    logDuration() {
      this.$nextTick(() => {
        if(this.$refs.videoPlayer)
          this.elementsStore.videoDuration = Math.floor(this.$refs.videoPlayer?.duration) || 10;
        else 
          this.elementsStore.videoDuration = 10;
      })
    },
    stopNBrowser(){
      if(!this.stb) return;
      try {
        // eslint-disable-next-line no-undef
        NBrowser_CachedPlayer.stop();
        // eslint-disable-next-line no-undef
        NBrowser_CachedPlayer.hidePlayer();
      } catch (e) {
        console.log(
        '%cNBrowser_CachedPlayer - Stop',
        'color: red',
        );
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.uploaded-video {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}
.video-overlay {
  text-shadow: 0 0 3px #000;
  color: white;
}
.stb-video {
  width: 100%;
  height: 100%;
  background: black;
}

video{
  height: inherit;
  width: inherit;
  object-fit: contain;
}
</style>
