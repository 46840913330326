import { defineStore } from "pinia";
import { useLocalStorage, useMouse } from "@vueuse/core";
import axios from "axios";
import APIRequest from "@/libs/cloud_auth";
import * as htmlToImage from "html-to-image";
import { nextTick } from "vue";
import { useAppStore } from "../app";

const NEW_DEFAULT = {};

const THEMES = [
  {
    type: "wayfinder",
    theme: [
      {
        primary: "#ff6d6d",
        secondary: "#24b472",
        txt_primary: "#FFFFFF",
        txt_secondary: "#FFFFFF",
      },
      {
        primary: "#685793",
        secondary: "#f1aa00",
        txt_primary: "#FFFFFF",
        txt_secondary: "#FFFFFF",
      },
      {
        primary: "#253444",
        secondary: "#00af8b",
        txt_primary: "#FFFFFF",
        txt_secondary: "#FFFFFF",
      },
      {
        primary: "#2543cf",
        secondary: "#4360e7",
        txt_primary: "#FFFFFF",
        txt_secondary: "#FFFFFF",
      },
      {
        primary: "#4b372f",
        secondary: "#b49286",
        txt_primary: "#FFFFFF",
        txt_secondary: "#FFFFFF",
      },
    ],
  },
  {
    type: "flights",
    theme: [
      {
        primary: "#2e2e2e",
        secondary: "#5f5f5f",
        tertiary: "#3c3c3c",
        txt_primary: "#ffffff",
        txt_secondary: "#FFFFFF",
      },
      {
        primary: "#daeef9",
        secondary: "#0069bd",
        tertiary: "#0091db",
        txt_primary: "#0069bd",
        txt_secondary: "#FFFFFF",
      },
      {
        primary: "#253444",
        secondary: "#00af8b",
        tertiary: "#253444",
        txt_primary: "#FFFFFF",
        txt_secondary: "#FFFFFF",
      },
      {
        primary: "#cfe7f2",
        secondary: "#3f3244",
        tertiary: "#60495A",
        txt_primary: "#3f3244",
        txt_secondary: "#FFFFFF",
      },
      {
        primary: "#c7ff5c",
        secondary: "#212121",
        tertiary: "#2c364a",
        txt_primary: "#212121",
        txt_secondary: "#FFFFFF",
      },
    ],
  },
  {
    type: "islamic",
    theme: [
      {
        primary: "#212121",
        secondary: "#222732",
        txt_primary: "#EEB9B9",
        txt_secondary: "#FFFFFF",
      },
      {
        primary: "#FAEDCD",
        secondary: "#FEFAE0",
        txt_primary: "#D4A373",
        txt_secondary: "#815F0E",
      },
      {
        primary: "#344E41",
        secondary: "#3A5A40",
        txt_primary: "#A3B18A",
        txt_secondary: "#DAD7CD",
      },
      {
        primary: "#D3EDEE",
        secondary: "#F1FAEE",
        txt_primary: "#457B9D",
        txt_secondary: "#1D3557",
      },
      {
        primary: "#F27059",
        secondary: "#F25C54",
        txt_primary: "#F9C48B",
        txt_secondary: "#FEF5EC",
      },
    ]
  },
  {
    type: "rates",
    theme: [
      {
        highlight: '#C7FF5C',
        primary: "#212121",
        secondary: "#222732",
        txt_primary: "#212121",
        txt_secondary: "#FFFFFF",
      },
      {
        highlight: '#382906',
        primary: "#FAEDCD",
        secondary: "#FEFAE0",
        txt_primary: "#FEFAE0",
        txt_secondary: "#815F0E",
      },
      {
        highlight: '#A28A2A',
        primary: "#344E41",
        secondary: "#3A5A40",
        txt_primary: "#FFFFFF",
        txt_secondary: "#DAD7CD",
      },
      {
        highlight: '#275F91',
        primary: "#CEE1F2",
        secondary: "#F1FAEE",
        txt_primary: "#F1FAEE",
        txt_secondary: "#457B9D",
      },
      {
        highlight: '#5ED4C4',
        primary: "#264653",
        secondary: "#2A9D8F",
        txt_primary: "#13242A",
        txt_secondary: "#FEF5EC",
      },
    ]
  }
];

const { x: mouseX, y: mouseY } = useMouse();
const clamp = (value, min, max) => {
  return value < min ? min : value > max ? max : value;
};

const filter = (node) => {
  const exclusionClasses = ['handle', 'container-widgetSettings', 'video-tag'];
  return !exclusionClasses.some((classname) => node.classList?.contains(classname));
}

export const composerStore = defineStore("composer", {
  state: () => ({
    mounted: false,
    elementSelected: false,
    locked: false,
    interacting: false,
    galleryBucket: 0,
    galleryUpload: false,
    galleryGroup: false,
    presentationDetails: {},
    presentation: NEW_DEFAULT,
    showOverlay: false,
    galleryType: "",
    showMenuSettings: false,
    indexElement: -1,
    indexDataArray: 0,
    configListView: false,
    temp: " ",
    settings: "default",
    preventDelete: false,
    x: 0,
    y: 0,
    color: "#fff",
    zoom: 1,
    zoomStep: 0.1,
    coordinatesX: 0,
    coordinatesY: 0,
    configX: 0,
    configY: 0,
    shape: "square",
    showGrid: useLocalStorage("grid", true),
    showGallery: false,
    showTvChannels: false,
    idInputText: 0,
    videoDuration: 10,
    selectedEditor: null,
    selectedTable: null,
    onEditor: false,
    dropzone: null,
    configSlides: false,
    indexArrayTemp: -1,
    check: false,
    saveDraft: false,
    dragFromGallery: false,
    test: false,
    column_index: 0,
    row_index: 0,
    indexRow: 0,
    indexCell: 0,
    intervalIndex: [],
    intervalIndexReal: [],
    tableEditor: false,
    selectedFolder: "",
    mergeWarning: false,
    merge: false,
    history: [],
    historyIndex: -1,
    historyLimit: 15, // Specify the history limit
    customThemes: THEMES,
    elementCopy: null,
    widgetLoading: false,
  }),

  actions: {
    bringToFront() {
      if (!this.selectedWidget || this.isWidgetLocked) return;
      if (this.indexDataArray != this.slideElements.length - 1) {
        let element = this.selectedWidget;
        this.deleteElement(this.indexElement);
        this.addElement(element);
      }
      this.configListView = false;
      this.addToHistory();
    },
    sendToBack() {
      if (!this.selectedWidget || this.isWidgetLocked) return;
      let element = this.selectedWidget;
      this.deleteElement(this.indexElement);
      this.slideElements.unshift(element);
      this.indexElement = 0;
      this.showMenuSettings = true;
      this.configListView = false;
      this.addToHistory();
    },
    sendBackward() {
      if (!this.selectedWidget || this.isWidgetLocked) return;
      if (this.indexElement != 0) {
        this.presentation.slides[this.indexDataArray].elements.splice(
          this.indexElement - 1,
          0,
          this.presentation.slides[this.indexDataArray].elements.splice(
            this.indexElement,
            1
          )[0]
        );
        this.indexElement -= 1;
      }
      this.configListView = false;
      this.addToHistory();
    },
    sendForward() {
      if (!this.selectedWidget || this.isWidgetLocked) return;
      if (this.indexElement < this.slideElements.length - 1) {
        this.presentation.slides[this.indexDataArray].elements.splice(
          this.indexElement + 1,
          0,
          this.presentation.slides[this.indexDataArray].elements.splice(
            this.indexElement,
            1
          )[0]
        );
        this.indexElement += 1;
      }
      this.configListView = false;
      this.addToHistory();
    },
    copyElement() {
      if (!this.selectedWidget || this.isWidgetLocked || this.currentWidgetNBrowser)
        return;

      this.elementCopy = JSON.parse(JSON.stringify(this.selectedWidget));
      this.addToHistory();
    },
    pasteElement() {
      if (!this.elementCopy || this.onEditor || this.isSlideLocked) return;
      const grid = document.querySelector("#grid");
      if (!grid) return;
      const element = JSON.parse(JSON.stringify(this.elementCopy));

      if (this.settings !== element.name) {
        this.showGallery = false;
      }
      this.settings = element.name;
      element.id = Date.now();

      const rect = grid.getBoundingClientRect();

      // Calculate mouse position relative to the grid
      const xPos = (mouseX.value - rect.left) / this.zoom - element.width / 2;
      const yPos = (mouseY.value - rect.top) / this.zoom - element.height / 2;

      element.top = clamp(yPos, 0, grid.offsetHeight - element.height);
      element.left = clamp(xPos, 0, grid.offsetWidth - element.width);

      this.slideElements.forEach((data) => {
        data.active = false;
      });

      this.addElement(element);
      this.slideElements[this.indexElement].active = true;

      this.showMenuSettings = true;
      this.handleScale();
      this.addToHistory();
    },
    handleScale() {
      nextTick(() => {
        let handles = document.querySelectorAll(".my-active-class .handle");
        handles.forEach((el) => {
          // If scale above 100% zoom => 1 / value < 1 ? 1 : 1 / value;
          el.style.scale = 1 / this.zoom;
        });
      });
    },
    // Will be useful in the future
    async takeThumbnailPic(canvas) {
        const index = this.indexDataArray;
        const el = canvas;
  
        try {
          const dataUrl = await htmlToImage.toJpeg(el, {
            backgroundColor: "transparent",
            width: el.clientWidth,
            height: el.clientHeight,
            canvasWidth: el.clientWidth / 2,
            canvasHeight: el.clientHeight / 2,
            imagePlaceholder: 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==',
            pixelRatio: 1,
            quality: 0.5,
            cacheBust: true,
            filter: filter, // Assuming filter is defined elsewhere
            style: {
              transformOrigin: "top left",
              transform: "scale(1)",
            },
            skipAutoScale: true,
          });
  
          if (dataUrl) {
            this.presentation.slides[index].settings.thumbnail = dataUrl;
          }
        } catch (error) {
          console.error("Error capturing image:", error);
        } finally {
          this.picInProgress = false;
        }
    },
    getTheme(name) {
      return this.customThemes.find((theme) => theme.type === name).theme;
    },
    resetHistory() {
      this.history = [];
      this.historyIndex = -1;
    },
    addToHistory() {
      nextTick().then(() => {
        const stateCopy = JSON.parse(
          JSON.stringify(
            this.presentation.slides.map((slide) => ({
              ...slide,
              elements: slide.elements.map((element) => ({
                ...element,
                active: false,
              })),
            }))
          )
        );

        this.history = this.history.slice(0, this.historyIndex + 1);
        this.history.push(stateCopy);
        this.historyIndex++;

        if (this.history.length > this.historyLimit) {
          this.history.shift();
          this.historyIndex--;
        }
      });
    },
    undo() {
      if (this.canUndo) {
        if (document.querySelector(".vue-drag-resize-rotate.dragging"))
          return false;

        this.undoRedoCommonTasks();

        const previousState = JSON.parse(
          JSON.stringify(this.history[--this.historyIndex])
        );
        this.presentation.slides = previousState;
      }
    },
    redo() {
      if (this.canRedo) {
        if (document.querySelector(".vue-drag-resize-rotate.dragging"))
          return false;

        this.undoRedoCommonTasks();

        const nextState = JSON.parse(
          JSON.stringify(this.history[++this.historyIndex])
        );
        this.presentation.slides = nextState;
      }
    },
    undoRedoCommonTasks() {
      if (this.selectedEditor) {
        this.selectedEditor.commands.blur();
        this.selectedEditor.setEditable(false);
      }
      if (this.selectedTable) {
        this.selectedTable.commands.blur();
      }
      this.onEditor = false;
      this.settings = "default";
      this.configSlides = false;
      this.showMenuSettings = false;
      this.indexElement = -1;
    },
    async getIslamicCalendar(date, lat = 41, lon = -8) {
      return new Promise((resolve, reject) => {
        axios.post(`islamic_calendar/?latitude=${lat}&longitude=${lon}&date=${date}`)
          .then((response) => {
            if(response?.status === 200){
              const data = response.data;
              resolve(data);
            }
            else{
              reject([]);
            }
          })
          .catch(() => {
            reject([]);
          });
      });
    },
    async getExchangeRates(source, currency) {
      return new Promise((resolve, reject) => {
        axios.post("get_exchange_rates/",{
            source,
            currency
        })
          .then((response) => {
            const data = response.data;
            resolve(data);
          })
          .catch(() => {
            reject([]);
          });
      });
    },
    async getTvChannels() {
      return new Promise((resolve, reject) => {
        APIRequest(
          "widgets/tvwidget/?page_size=999999&tenant=" +
            useAppStore().getCurrentTenant
        )
          .then((response) => {
            const data = response.data["results"];
            const urlList = [];
            data.forEach((element) => {
              urlList.push({
                id: element["id"],
                name: element["name"],
                value: element["url"],
              });
            });
            resolve(urlList);
          })
          .catch(() => {
            reject([]);
          });
      });
    },
    async saveTvChannel(name, url) {
      return new Promise((resolve, reject) => {
        APIRequest("widgets/tvwidget/", "post", {
          name: name,
          url: url,
          tenant: useAppStore().getCurrentTenant,
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async updateTvChannel(id, name, url) {
      return new Promise((resolve, reject) => {
        APIRequest(`widgets/tvwidget/${id}/`, "patch", {
          name: name,
          url: url,
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async deleteTvChannel(id) {
      return new Promise((resolve, reject) => {
        APIRequest(`widgets/tvwidget/${id}/`, "delete")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getIFrameUrls() {
      return new Promise((resolve, reject) => {
        APIRequest(
          "widgets/iframe/?page_size=999999&tenant=" +
            useAppStore().getCurrentTenant
        )
          .then((response) => {
            // make a name and value array list
            const data = response.data["results"];
            const urlList = [];
            data.forEach((element) => {
              urlList.push({
                id: element["id"],
                name: element["name"],
                value: element["url"],
              });
            });

            resolve(urlList);
          })
          .catch(() => {
            reject([]);
          });
      });
    },
    async saveIFrame(name, url) {
      return new Promise((resolve, reject) => {
        APIRequest("widgets/iframe/", "post", {
          name: name,
          url: url,
          tenant: useAppStore().getCurrentTenant,
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async updateIFrame(id, name, url) {
      return new Promise((resolve, reject) => {
        APIRequest(`widgets/iframe/${id}/`, "patch", {
          name: name,
          url: url,
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async deleteIFrame(id) {
      return new Promise((resolve, reject) => {
        APIRequest(`widgets/iframe/${id}/`, "delete")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async openJson(url) {
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async openTemplate(id) {
      return new Promise((resolve, reject) => {
        APIRequest(`/compose/presentation/${id}/`)
          .then((response) => {
            const result = response.data;
            if (
              result.source_type === "local" &&
              String(useAppStore().getCurrentTenant) !==
                String(result.source_ref_id)
            ) {
              reject();
            }

            if (
              result.source_type === "group" &&
              String(useAppStore().getTenantGroup) !==
                String(result.source_ref_id)
            ) {
              reject();
            }

            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async saveTemplate(title) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append(
          "template_data.json_file_path",
          new File([JSON.stringify(this.presentation)], `${Date.now()}.json`, {
            type: "application/json",
          })
        );

        formData.append(
          "template_data.thumbnail",
          this.presentation.slides[0].settings.thumbnail
        );
        formData.append(
          "template_data.orientation",
          this.presentationDetails.template.orientation
        );
        formData.append(
          "template_data.type",
          this.presentationDetails.template.type
        );
        formData.append("type", this.presentationDetails.type);
        formData.append("name", title);

        APIRequest(
          `compose/presentation/${this.presentationDetails.id}/`,
          "patch",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
          .then((response) => {
            // Resolve the Promise with the response data
            resolve(response.data);
          })
          .catch((error) => {
            // Reject the Promise with the error
            reject(error);
          });
      });
    },
    addSlide() {
      this.presentation.slides.push({
        id: Date.now(),
        settings: {
          show: true,
          lock: false,
          background: {
            type: "color",
            value: "#fff",
          },
          delay_type: false,
          transition: 10,
          animation: "fade",
          animation_time: 1,
          thumbnail: "",
        },
        elements: [],
      });
      this.saveDraft = true;
      localStorage.setItem("presentation", JSON.stringify(this.presentation));
    },
    addElement(element) {
      if (
        this.presentation.slides[this.indexDataArray].settings.lock === false
      ) {
        this.presentation.slides[this.indexDataArray].elements.push(element);
        this.saveDraft = true;
        this.indexElement = this.slideElements.length - 1;
        this.showMenuSettings = true;
        localStorage.setItem("presentation", JSON.stringify(this.presentation));
      }
    },
    deleteElement(index) {
      if (
        this.presentation.slides[this.indexDataArray].settings.lock === false
      ) {
        this.presentation.slides[this.indexDataArray].elements.splice(index, 1);
        this.saveDraft = true;
        this.settings = "default";
        this.indexElement = -1;
        this.showMenuSettings = false;
        this.configListView = false;
        this.check = true;
        localStorage.setItem("presentation", JSON.stringify(this.presentation));
      }
    },
    disableElements() {
      this.settings = "default";
      this.showMenuSettings = false;
      this.indexElement = -1;
      for (let i = 0; i < this.slideElements.length; i++) {
        this.slideElements[i].active = false;
      }
    },
  },
  getters: {
    canUndo() {
      return this.historyIndex > 0;
    },
    canRedo() {
      return this.historyIndex < this.history.length - 1;
    },
    isSlideLocked() {
      return this.presentation.slides[this.indexDataArray]?.settings?.lock || this.presentation.slides[this.indexDataArray]?.settings?.admin_locked;
    },
    isWidgetLocked(){
      return this.selectedWidget?.settings?.lock || this.selectedWidget?.settings?.admin_locked;
    },
    slideElements() {
      return this.presentation.slides[this.indexDataArray].elements;
    },
    selectedWidget() {
      if(!this.presentation) return null;
      return this.slideElements[this.indexElement];
    },
    checkUpdate: (state) => {
      if (state.data !== state.dataTemp) return true;
      return false;
    },
    checkNBrowserWidgets() {
      let found = false;
      this.slideElements.forEach((element) => {
        if (["VideoWidget", "TVWidget"].includes(element.name)) found = true;
      });

      if (found) return true;
      return false;
    },
    hasVideoWidget() {
      if (!this.slideElements) return false;
      if (this.slideElements.find((element) => element.name === "VideoWidget"))
        return true;
      return false;
    },
    currentWidgetNBrowser() {
      if (!this.slideElements[this.indexElement]) return false;
      if (
        ["VideoWidget", "TVWidget"].includes(
          this.slideElements[this.indexElement].name
        )
      )
        return true;
      return false;
    },
    hasLockPermissions() {
      return ["client-admin", "nonius-admin"].includes(
        useAppStore().getCurrentRole
      );
    },
    rightMenuOpen() {
      return this.showGallery || this.settings == "TVWidget";
    },
  },
});
