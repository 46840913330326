// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.background[data-v-021941ba] {
  width: 100vw;
  height: 100vh;
  background: black !important;
}
.no-license[data-v-021941ba] {
  position: absolute;
  bottom: 25px;
  right: 30px;
  color: white;
  display: flex;
  gap: 15px;
}
.frame[data-v-021941ba] {
  width: 1920px;
  height: 1080px;
  background: white;
  transform-origin: top left;
  -webkit-transform-origin: top left;
  word-wrap: break-word;
  white-space: break-spaces;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0;
}
.frame-background[data-v-021941ba] {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  background-position: center;
}
p[data-v-021941ba] {
  margin: 0;
  padding: 0;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
